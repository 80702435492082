import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import * as PropTypes from "prop-types"

import style from "./index.module.css";

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Clients extends React.Component {
  render() {
    const content = this.props.data.page
    return (
      <div>
        <Helmet>
          <title>{content.title} - Strawberry Earth</title>
          {content.metaDescription &&
            <meta name="description" content={content.metaDescription} />
          }
        </Helmet>
        <div className={style.content}>
          {content.title &&
            <h1 className={style.title}>{content.title}</h1>
          }
        </div>
        <div className={style.list}>
          {content.clients && content.clients.map(client => {
            return (
              <article className={style.client} key={client.id}>
                <Img
                  className={style.imageContainer}
                  imgStyle={{
                    objectFit: `contain`
                  }}
                  resolutions={client.logo.resolutions}
                  backgroundColor={true}
                />
              </article>
            )
          })}
        </div>
      </div>
    )
  }
}

Clients.propTypes = propTypes

export default Clients

export const ClientsPageQuery = graphql`
  query ClientsPageQuery {
    page: contentfulPageClients {
      title
      metaDescription
      clients {
        id
        name
        logo {
          resolutions(width: 300) {
            src
            srcSet
            srcWebp
            srcSetWebp
          }
        }
      }
    }
  }
`